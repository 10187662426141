import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { GetPersonsWithAccount, PersonOrder } from '../../../Apollo';
import {
  DEBOUNCE_DELAY,
  MIN_SEARCH_LENGTH,
  ROW_PER_PAGE_DEFAULT
} from 'Theme/config';
import useNotification from 'Hooks/useNotification';
import UsersTable from './UsersTable';
import { calculateNewPage } from 'Tools/calculateNewPage';
import useTableHandlers from 'Hooks/useTableHandlers';

interface Props {
  isMobileP: boolean;
}

function UsersTableContainer(props: Readonly<Props>) {
  const { isMobileP } = props;
  const { accountCode } = useParams<IUriParams>();
  const { onError } = useNotification();

  const [selectedIds, setSelectedIds] = useState<string[] | null>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState<PersonOrder>(PersonOrder.NameAsc);
  const [personFilter, setPersonFilter] = useState<string | null>(null);


  const onUpdate = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });


  const variables = {
    personFilter: {
      accountCode: accountCode === 'all' ? null : accountCode,
      contactDetails: personFilter ?? null
    },
    offset: currentPage * rowsPerPage,
    first: rowsPerPage,
    orderBy
  };

  const onChangeUsersSearch = (value: string) => {
    const filter = value.trim();

    if (filter && filter.length < MIN_SEARCH_LENGTH) {
      return;
    }

    setPersonFilter(filter);
  };

  const handleSearch = (search: string) => {
    debouncedOnChangeUsersSearch(search);
    setSelectedIds([]);
  };

  const debouncedOnChangeUsersSearch = debounce(
    onChangeUsersSearch,
    DEBOUNCE_DELAY
  );

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const onChangeOrderBy = (orderBy: string) => {
    setOrderBy(orderBy as PersonOrder);
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRefetch = () => {
    refetch();
    setSelectedIds([]);
  };

  const { data, loading: fetching, refetch } = useQuery<{
    persons: IPaginatedList<IPersonWithAccount>;
  }>(GetPersonsWithAccount, {
    onError,
    variables
  });

  let loading = fetching;
  let rows: Array<{ node: IPersonWithAccount }> = [];
  let totalCount = rowsPerPage;

  if (data?.persons) {
    rows = data.persons.edges;
    totalCount = data.persons.totalCount;
  } else {
    loading = true;
  }

  const selectedCount = selectedIds == null ? totalCount : selectedIds.length;

  return (
    <UsersTable
      accountCode={accountCode}
      rows={rows}
      loading={loading}
      selectedCount={selectedCount}
      selectedIds={selectedIds}
      filter={personFilter}
      totalCount={totalCount}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      onSearch={handleSearch}
      onChangeOrderBy={onChangeOrderBy}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSelect={handleSelect}
      onUnselect={handleUnselect}
      onChangePage={onChangePage}
      onReloadUsers={handleRefetch}
      isMobileP={isMobileP}
    />
  );
}

export default UsersTableContainer;
