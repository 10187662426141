import { useQuery } from '@apollo/client';
import {
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Typography
} from '@mui/material';
import TestIds from 'Tests/TestIds';
import { PersonAvatar, PaperTitle } from 'Theme/components';
import Skeleton from 'Theme/components/Skeleton';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import { userFullName } from 'Tools';
import { useState } from 'react';
import { GetAdministrators } from 'Apollo/queries/person/GetAdministrators';
import colors from 'Theme/constants/colors';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';

interface Props {
  person: IPersonWithAccount;
}

const Administrators = (props: Props) => {
  const { person } = props;
  const ts = useTranslate();
  const { onError } = useNotification();

  const rowsPerPage = 5;

  const [currentPage, setCurrentPage] = useState<number>(0);

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => setCurrentPage(page);

  const { data, loading, error } = useQuery<GetAdministratorsData>(
    GetAdministrators,
    {
      onError,
      variables: {
        personIdentifier: person.immutableId,
        first: rowsPerPage,
        offset: rowsPerPage * currentPage
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const { totalCount = -1 } = data?.administrators || {};
  const skeletonArray = Array.from({ length: rowsPerPage });

  return (
    <Paper style={{ marginBottom: '2em' }}>
      <PaperTitle title={ts('page.userProfile.section.administrators.title')} />
      {error && <div>{error.message}</div>}
      {!error && (
        <List>
          {loading &&
            skeletonArray.map((_: any, index: number) => (
              <div key={('skeleton' + index)}>
                <ListItem alignItems="center" key={('listItem' + index)}>
                  <ListItemAvatar>
                    <Skeleton variant="circle" width={40} height={40} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Skeleton
                        component="span"
                        variant="rectangular"
                        width="20%"
                        style={{
                          marginBottom: '8px'
                        }}
                      />
                    }
                    secondary={
                      <Skeleton
                        component="span"
                        variant="rectangular"
                        width="10%"
                      />
                    }
                  />
                </ListItem>
                {index === skeletonArray.length - 1 ? (
                  <Divider variant="fullWidth" />
                ) : (
                  <Divider variant="inset" />
                )}
              </div>
            ))}
          {!loading && data?.administrators.totalCount === 0 && (
            <Typography variant="body2" paragraph sx={{ p: '16px', wordWrap: 'break-word' }}>
              {ts('page.userProfile.section.administrators.noAdministrator', {value: shortAccountlabel(person.account as IAccount) })}
            </Typography>
          )}
          {!loading &&
            data?.administrators.totalCount !== 0 &&
            data?.administrators.edges.map(
              (administrator: { node: IPerson }, index: number) => {
                const {
                  node,
                  node: { firstName, lastName, email }
                } = administrator;

                let mailtoLink = email ? `mailto:${email}` : '';

                return (
                  <div key={'administrator-' + index}>
                    <ListItem
                      key={'admin' + index}
                      alignItems="center"
                      data-testid={
                        TestIds.pages.userProfile.administrators.adminItem +
                        '-' +
                        index
                      }
                    >
                      <ListItemAvatar>
                        <PersonAvatar component="span" person={node} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={userFullName(firstName, lastName)}
                        secondary={
                          email ? (
                            <a
                              href={mailtoLink}
                              style={{ color: colors.blue[500] }}
                            >
                              {email}
                            </a>
                          ) : (
                            ''
                          )
                        }
                        data-testid={
                          TestIds.pages.userProfile.administrators.identity +
                          '-' +
                          index
                        }
                      />
                    </ListItem>
                    {index === data?.administrators.edges.length - 1 ? (
                      <Divider variant="fullWidth" />
                    ) : (
                      <Divider variant="inset" />
                    )}
                  </div>
                );
              }
            )}
        </List>
      )}
      {!loading && data?.administrators.totalCount !== 0 && (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={null}
          rowsPerPageOptions={[]}
          page={currentPage}
          onPageChange={onChangePage}
          labelDisplayedRows={({ from, to, count }: LabelDisplayedRowsArgs) =>
            ts('common.pagination.displayedRows', {
              from,
              to,
              count
            })
          }
        />
      )}
    </Paper>
  );
};

export default Administrators;
