import { Box, Grid, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip/Chip';
import { PersonContext } from 'Context/CurrentPerson';
import { LocalContext } from 'Context/Local.context';
import useTranslate from 'Hooks/useTranslate';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { useContext } from 'react';
import { useStyles } from './Header.styles';
import AccountBadge from './AccountBadge';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

interface Props {}

function ConditionalHeader(props: Readonly<Props>) {
  const ts = useTranslate();
  const classes = useStyles();
  const { isAdminOfOnlyOneAccount, hasTooManyAccounts, hasLimitedAccounts } = useCurrentUserInfo();
  const { currentPerson } = useContext(PersonContext);
  const { currentAccount, allAccountsSelected, availableAccounts } = useContext(LocalContext);

  const isAccountDisabled = currentAccount !== null && !currentAccount.active;
  const adminOfOneAccountNeedsContext = isAdminOfOnlyOneAccount && allAccountsSelected;

  return (
    <div style={{ width: '30%', justifyContent: 'flex-end', display: 'flex' }}>
      {/* ----- SELECTED ACCOUNT -----*/}
      {currentAccount !== null && !hasLimitedAccounts && (
        <Box display={{ sm: 'block' }}>
          <Tooltip
            title={`${shortAccountlabel(currentAccount)} ${
              isAccountDisabled ? ts('common.disabledAccount') : ''
            }`}
          >
            <Grid container alignItems="center">
              <Grid item>
                <AccountBadge isAccountDisabled={isAccountDisabled} />
              </Grid>
              <Grid item>
                {currentAccount !== null && (
                  <>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ display: { xs: 'none', md: 'block' }, maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                      noWrap
                    >
                      {currentAccount.name && shortAccountlabel(currentAccount)}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ display: { xs: 'block', md: 'none' } }}
                      noWrap
                    >
                      {currentAccount.code}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Tooltip>
        </Box>
      )}

      {/* ----- admin of more than 20 accounts WITH NO SELECTED ACCOUNT-----*/}
      {allAccountsSelected && hasTooManyAccounts && (
        <Chip
          label={ts('component.administratedAccountsSelector.allAccounts')}
          variant="filled"
          sx={{ backgroundColor: 'transparent' }}
        />
      )}

      {/* ----- even if no current account, the administrator of only one account need to see in which account he is working on -----*/}
      {adminOfOneAccountNeedsContext && (
        <Box className={classes.rightDivider} pr={2} display={{ sm: 'block' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid sx={{ display: { sx: 'none', md: 'flex' } }} item>
              <AccountBadge isAccountDisabled={!availableAccounts[0].active} />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary" noWrap component={'div'}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {shortAccountlabel(availableAccounts[0])}
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  {currentPerson.account?.code}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default ConditionalHeader;
