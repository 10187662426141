import useTranslate from './useTranslate';
import { ShorterUserNameCell } from 'Components/Shared/UserNameCell';
import Skeleton from "Theme/components/Skeleton";
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';
import { PersonOrder } from 'Apollo';
import { Person as UserIcon } from '@mui/icons-material';
import { IUserColumnsConfig } from './useColumnsUsers';
import { FormattedDate } from 'react-intl';
import { TableCollectionColumn } from 'Components/Shared/Table';
import UserStatusPopover from 'Components/Shared/Chips/UserStatusChip/UserStatusPopover';

export const defaultLightConfig: IUserColumnsConfig = {
  isUserHidden: false,
  isEmailHidden: false,
  isStatusHidden: true,
  isAccountHidden: true,
  isCreationDateHidden: true,
  isActivationDateHidden: true,
  isLanguageHidden: true,
  isPhoneNumberHidden: true,
  isMobileNumberHidden: true
};

export const useColumnsUsersLight = (
  columnsConfig: IUserColumnsConfig
): TableCollectionColumn<{ node: IPersonWithAccount }>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'user',
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: columnsConfig.isUserHidden ?? defaultLightConfig.isUserHidden,
      renderHead: () => (
        <div style={{ display: 'flex' }}>
          <UserIcon />
        </div>
      ),
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <ShorterUserNameCell person={person} />
    },
    {
      key: 'email',
      hidden: columnsConfig.isEmailHidden ?? defaultLightConfig.isEmailHidden,
      renderHead: () => ts('page.users.index.email'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person?.email
    },
    {
      key: 'status',
      orderBy: {
        asc: PersonOrder.StatusAsc,
        desc: PersonOrder.StatusDesc
      },
      hidden: columnsConfig.isStatusHidden ?? defaultLightConfig.isStatusHidden,
      renderHead: () => ts('page.users.index.allStatus'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserStatusPopover person={person} />
    },
    {
      key: 'account',
      hidden:
        columnsConfig.isAccountHidden ?? defaultLightConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <div style={{maxWidth: '200px',  wordWrap: 'break-word'}}>{person.account?.name}</div>
    },
    {
      key: 'language',
      hidden:
        columnsConfig.isLanguageHidden ?? defaultLightConfig.isLanguageHidden,
      renderHead: () => ts('page.users.index.language'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.language?.label
    },
    {
      key: 'phoneNumber',
      hidden:
        columnsConfig.isPhoneNumberHidden ??
        defaultLightConfig.isPhoneNumberHidden,
      renderHead: () => ts('page.users.index.phoneNumber'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.phoneNumber
    },
    {
      key: 'mobileNumber',
      hidden:
        columnsConfig.isMobileNumberHidden ??
        defaultLightConfig.isMobileNumberHidden,
      renderHead: () => ts('page.users.index.mobileNumber'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.mobileNumber
    },
    {
      key: 'creationDate',
      hidden:
        columnsConfig.isCreationDateHidden ??
        defaultLightConfig.isCreationDateHidden,
      renderHead: () => ts('page.users.index.creationDate'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => (
        <FormattedDate
          value={person.creationDate}
          year="numeric"
          month="2-digit"
          day="2-digit"
          hour="2-digit"
          minute="2-digit"
        />
      )
    },
    {
      key: 'activationDate',
      hidden:
        columnsConfig.isActivationDateHidden ??
        defaultLightConfig.isActivationDateHidden,
      renderHead: () => ts('page.users.index.activationDate'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) =>
        person.activationDate && (
          <FormattedDate
            value={person.activationDate}
            year="numeric"
            month="2-digit"
            day="2-digit"
            hour="2-digit"
            minute="2-digit"
          />
        )
    }
  ];
};
