import { Theme } from '@mui/material';
import { CdsColorBrandSecondary95 } from '@cegid/design-tokens';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'Theme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  allSelectedRow: {
    background: CdsColorBrandSecondary95,
    '&:hover': {
      backgroundColor: CdsColorBrandSecondary95
    }
  },
  noHoverRow:{
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  disabledRow: {
    background: colors.grey[200],
    opacity: 0.8
  },
  selectedRow: {
    backgroundColor: colors.blue[30]
  },
  notice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  tableRoot: {
    backgroundColor: colors.white
  },
  tableHead: {
    borderTop: '1px solid #ddd'
  },
  cellRoot: {
    // 'word-break': 'break-word'
  },
  emptyRow: {
    position: 'relative',
    height: '60vh'
  },
  emptyCell: {
    padding: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderBottom: 'none'
  }
}));
