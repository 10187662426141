import { ServiceSubscriptionOrder } from 'Apollo';
import useTranslate from './useTranslate';
import Skeleton from 'Theme/components/Skeleton';
import { useMediaQuery } from '@mui/material';
import { MoreHorizOutlined as MoreIcon } from '@mui/icons-material'
import {
  CellAccessByDefault,
  TableCollectionColumn
} from '../Components/Shared';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import CellService from 'Components/Shared/CellService';
import CellAdministratedValidation from 'Components/Shared/CellAdministratedValidation';
import mediaQueries from 'Theme/constants/mediaQueries';



export interface IGroupServicesgroupServicesColumnsConfig {
  isServiceHidden?: boolean;
  isDescriptionHidden?: boolean;
  isFamilyHidden?: boolean;
  isAccountHidden?: boolean;
  isAdminValidationHidden?: boolean;
  isAccessByDefaultHidden?: boolean;
}

export const defaultGroupServicesConfig: IGroupServicesgroupServicesColumnsConfig = {
  isServiceHidden: false,
  isDescriptionHidden: false,
  isFamilyHidden: false,
  isAccountHidden: false,
  isAdminValidationHidden: false,
  isAccessByDefaultHidden: false
};

export const useColumnsGroupServices = (groupServicesColumnsConfig: IGroupServicesgroupServicesColumnsConfig): TableCollectionColumn<IServiceSubscriptionWithCount>[] => {

  const ts = useTranslate();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  return [
    {
      key: 'service',
      orderBy: {
        asc: ServiceSubscriptionOrder.NameAsc,
        desc: ServiceSubscriptionOrder.NameDesc
      },
      hidden: groupServicesColumnsConfig.isServiceHidden ?? defaultGroupServicesConfig.isServiceHidden,
      renderHead: () => ts('page.userProfile.services.service'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (
        <CellService serviceSubscription={serviceSubscription} />
)
    },
    {
      key: 'description',
      hidden: groupServicesColumnsConfig.isDescriptionHidden ?? defaultGroupServicesConfig.isDescriptionHidden,
      renderHead: () => ts('common.terminology.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => !isMobileP ? serviceSubscription.service.description : (
          <IconButtonWithPopOver
            customIcon={<MoreIcon />}
            description={serviceSubscription.service.description ?? ''}
          />
        )
    },
    {
      key: 'family',
      orderBy: {
        asc: ServiceSubscriptionOrder.FamilyAscThenNameAsc,
        desc: ServiceSubscriptionOrder.FamilyDescThenNameAsc
      },
      hidden: groupServicesColumnsConfig.isFamilyHidden ?? defaultGroupServicesConfig.isFamilyHidden,
      renderHead: () => ts('page.userProfile.services.family'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => serviceSubscription.service.family
    },
    {
      key: 'account',
      orderBy: {
        asc: ServiceSubscriptionOrder.AccountLabelAscThenNameAsc,
        desc: ServiceSubscriptionOrder.AccountLabelDescThenNameAsc
      },
      hidden: groupServicesColumnsConfig.isAccountHidden ?? defaultGroupServicesConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => <div style={{maxWidth: '400px', wordWrap: 'break-word'}}>{serviceSubscription.service.groupCode === 'ADM-2' ? serviceSubscription.targetAccount!.name : serviceSubscription.account.name}</div>
    },
    {
      key: 'administratedValidation',
      hidden: groupServicesColumnsConfig.isAdminValidationHidden ?? defaultGroupServicesConfig.isAdminValidationHidden,
      renderHead: () => ts('page.userProfile.services.withValidation'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (<CellAdministratedValidation serviceSubscription={serviceSubscription} />)
    },
    {
      key: 'accessByDefault',
      hidden: groupServicesColumnsConfig.isAccessByDefaultHidden ?? defaultGroupServicesConfig.isAccessByDefaultHidden,
      renderHead: () => ts('page.userProfile.services.auto'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (<CellAccessByDefault serviceSubscription={serviceSubscription} />)
    }
  ];
};
