import { useContext, useState } from 'react';
import { PersonContext } from 'Context/CurrentPerson';
import LocalStorage from 'Tools/localStorage';
import { PersonOrder } from 'Apollo';
import useTranslate from 'Hooks/useTranslate';
import { Button, Hidden, Paper, Typography } from '@mui/material';
import { Add as AddIcon, Person as UserIcon } from '@mui/icons-material';
import { MAX_SELECTED } from 'Theme/config';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import TableCollectionColumnSelection from 'Theme/components/TableCollection/TableCollectionColumnSelection';
import ActionOnSelectedUsers from '../ActionOnSelectedUsers';
import { useStyles } from './UsersTable.styles';
import ModalUsersCreation from 'Components/ModalUsersCreation';
import TestIds from 'Tests/TestIds';
import ExpandedSearch from '../../../Components/Shared/ExpandedSearch';
import { useColumnsUsersLight } from 'Hooks/useColumnsUsersLight';
import { useColumnsUsers } from 'Hooks/useColumnsUsers';
import ContextualHelp from 'Components/Shared/ContextualHelp';
import EmptyState from 'Components/Shared/EmptyState';

interface Props {
  accountCode: string;
  loading: boolean;
  rows: any[];
  selectedCount: number;
  selectedIds: string[] | null;
  totalCount: number;
  orderBy: PersonOrder;
  rowsPerPage: number;
  currentPage: number;
  onReloadUsers: any;
  isMobileP: boolean;
  filter: string | null;
  onSearch: (search: string) => void;
  onChangeOrderBy: (orderBy: string) => void;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
  onSelect: (ids: string[] | null) => void;
  onUnselect: (ids: string[] | null) => void;
  onChangePage: (newPage: number) => void;
}

function UsersTable(props: Readonly<Props>) {
  const {
    totalCount,
    selectedCount,
    loading,
    rows,
    filter,
    orderBy,
    rowsPerPage,
    currentPage,
    selectedIds,
    onReloadUsers,
    isMobileP,
    onSelect,
    onUnselect,
    onSearch,
    onChangeOrderBy,
    onChangeRowsPerPage,
    onChangePage
  } = props;

  const classes = useStyles();
  const { currentPersonRoles } = useContext(PersonContext);
  const ts = useTranslate();

  const [columns, setColumns] = useState(
    LocalStorage.deserializeColumns(
      useColumnsUsers({}),
      LocalStorage.UsersColumns ?? []
    )
  );

  const [lightColumns, setLightColumns] = useState(
    LocalStorage.deserializeColumns(
      useColumnsUsersLight({}),
      LocalStorage.LightUsersColumns ?? []
    )
  );

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const handleSearch = (search: string) => {
    onSearch(search);
  };

  return <>
    <Paper className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
          <Hidden smDown>
            <Typography variant="h5">{ts('page.users.title')}</Typography>
          </Hidden>
          <ContextualHelp
            content={ts('page.users.help')}
          />
        </div>
        <div className={classes.toolbarRight}>
          {(currentPersonRoles.isAccountAdmin ||
            currentPersonRoles.canWriteAll) && (
            <Button
              color="primary"
              onClick={() => setModalIsOpen(!modalIsOpen)}
              data-testid={TestIds.pages.users.inviteUserButton}
            >
              <AddIcon
                className={classes.addUserButtonIcon}
                data-testid={TestIds.pages.users.inviteUserIcon}
              />
              {ts('page.users.action.addUser')}
            </Button>
          )}
          <TableCollectionColumnSelection
            columns={isMobileP ? lightColumns : columns}
            onChange={(newColumns: any) => {
              if (isMobileP) {
                setLightColumns(newColumns);
                LocalStorage.LightUsersColumns = LocalStorage.serializeColumns(
                  newColumns
                );
              } else {
                setColumns(newColumns);
                LocalStorage.UsersColumns = LocalStorage.serializeColumns(
                  newColumns
                );
              }
            }}
            onReset={() => {
              if (isMobileP) {
                LocalStorage.LightUsersColumns = null;
              } else {
                LocalStorage.UsersColumns = null;
              }
            }}
          />
        </div>
      </div>
      <ExpandedSearch
        onChange={handleSearch}
        testId={TestIds.pages.users.searchUserInput + 'UsersTable'}
        placeholder={ts('component.searchBars.placeholder.users')}
      />
      <TableCollection
        loading={loading}
        columns={isMobileP ? lightColumns : columns}
        rows={rows}
        getRowId={({ node: person }: { node: IPersonWithAccount }) =>
          person.immutableId
        }
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={currentPage}
        onChangePage={onChangePage}
        selectedIds={selectedIds}
        onUnselect={onUnselect}
        onSelect={onSelect}
        maxSelected={MAX_SELECTED}
        totalCount={totalCount}
        isSelectAllDisabled={filter ? filter.length > 0 : false}
        messages={{
          maxSelectedExceeded: 'page.users.maxLimitExceeded',
          pageAllSelected: 'page.users.pageSelectedUserCount',
          selectAll: 'page.users.selectUserCount'
        }}
        emptyStateData={
          <EmptyState
            icon={<UserIcon />}
            primary={ts('page.users.table.emptyStatePrimary')}
          />
        }
      />
    </Paper>

    <ActionOnSelectedUsers
      selectedIds={selectedIds ?? []}
      selectAllEnabled={selectedIds == null}
      selectedCount={selectedCount}
      onReloadUsers={onReloadUsers}
      disabled={selectedCount > MAX_SELECTED}
      searchTerm={filter ?? ''}
      onSelect={onSelect}
      totalCount={totalCount}
      loading={loading}
    />
    {modalIsOpen && (
      <ModalUsersCreation
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    )}
  </>;
}

export default UsersTable;
