import { createRef, useState } from 'react';
import {
  Drawer,
  Accordion,
  AccordionSummary,
  IconButton,
  Popper,
  Tooltip,
  Theme,
  Button,
  useMediaQuery,
  Typography,
  Box
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  GetApp as IconGetApp,
  ExpandMore as IconExpandMore,
  Person as IconPerson,
  BubbleChart as IconBubbleChart,
  Delete as IconDelete
} from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import CurrentPerson from 'Context/CurrentPerson';
import AddSubscriptions from './AddSubscriptions';
import ExportUsers from './ExportUsers';
import RemoveUsers from './RemoveUsers';
import useTranslate from 'Hooks/useTranslate';
import CustomStatus from 'Components/Shared/Chips/CustomStatus';
import mediaQueries from 'Theme/constants/mediaQueries';
import { MAX_SELECTED } from 'Theme/config';

interface Props {
  selectedIds: string[];
  selectAllEnabled: boolean;
  selectedCount: number;
  totalCount: number;
  onReloadUsers: () => void;
  disabled: boolean;
  searchTerm: string;
  onSelect: (ids: string[] | null) => void;
  loading: boolean;
}

type DisplayAction = 'addSubscriptions' | 'removeUsers' | 'exportUsers';

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      borderRadius: '20px 20px 0 0',
      maxHeight: '90%'
    },
    expansionPanel: {
      backgroundColor: 'transparent'
    },
    expansionPanelSummary: {
      cursor: 'auto',
      // Required for the text ellipsis
      overflow: 'hidden'
    },
    headerLeft: {
      display: 'flex',
      alignItems: 'center'
    },
    headerLeftIcon: {
      marginRight: theme.spacing(1),
      verticalAlign: 'middle'
    },
    // Taken from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/popper/ScrollPlayground.js#L43-L101
    popper: {
      // This popper is used in a drawer so it needs to appear on top of it
      zIndex: theme.zIndex.modal + 1,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
        }
      },
      willChange:
        'auto !important' /* the default value 'transform' blurs the text */
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      }
    }
  });

function ActionOnSelectedUsers(props: Props & WithStyles<typeof styles>) {
  const ts = useTranslate();
  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);
  const {
    selectedCount,
    totalCount,
    selectedIds,
    selectAllEnabled,
    onReloadUsers,
    onSelect,
    disabled,
    classes,
    searchTerm,
    loading
  } = props;

  const [displayAction, setDisplayAction] = useState<DisplayAction | null>(
    null
  );

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const buttonRef = createRef<HTMLButtonElement>();

  if (selectedCount <= 0 && Boolean(displayAction)) {
    // If the drawer is expanded and all users are unselected, collapse it
    setDisplayAction(null);
  }

  const isExpanded =
    // removeUsers doesn't need the drawer to be expanded
    Boolean(displayAction) && displayAction !== 'removeUsers';

  return (
    <CurrentPerson.Consumer>
      {({ currentPersonRoles }: CurrentPersonContext) => (
        <>
          {selectedCount > 0 && <div style={{ height: '72px' }} />}
          <Drawer
            anchor="bottom"
            variant={isExpanded ? 'temporary' : 'persistent'}
            open={selectedCount > 0}
            classes={{ paper: classes.drawer }}
          >
            <Accordion
              expanded={isExpanded}
              classes={{ root: classes.expansionPanel }}
            >
              <AccordionSummary
                classes={{ content: classes.expansionPanelSummary }}
                expandIcon={
                  !isExpanded ? null : (
                    <IconExpandMore
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (displayAction) {
                          setDisplayAction(null);
                        }
                      }}
                    />
                  )
                }
              >
                <div style={{ 
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // Required for the text ellipsis
                  overflow: 'hidden',
                  flexDirection: isMobileP ? 'column' : 'row' 
                }}>
                  <div>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconPerson
                        color="primary"
                        classes={{ root: classes.headerLeftIcon }}
                      />
                      <div>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="div"
                          classes={{ root: classes.headerLeft }}
                        >
                          {selectedCount === totalCount &&
                          searchTerm.length === 0
                            ? ts('page.users.index.allUsersSelected', {
                                totalCount: totalCount
                              })
                            : ts('page.users.index.selectedUserCount', {
                                count: selectedCount,
                                totalCount: totalCount
                              })}
                        </Typography>
                        {!selectAllEnabled &&
                          selectedIds.length > MAX_SELECTED &&
                          isMobileP && (
                            <CustomStatus
                              type="error"
                              label={ts('table.maxSelection', {
                                value: MAX_SELECTED
                              })}
                            />
                          )}
                      </div>
                    </Box>
                  </div>
                  <div>
                    {currentPersonRoles.isAccountAdmin && (
                      <>
                        <Tooltip title={ts('common.action.subscribe')}>
                          <IconButton
                            component="button"
                            data-testid={TestIds.pages.users.subscriptionButton}
                            color={
                              displayAction === 'addSubscriptions'
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => setDisplayAction('addSubscriptions')}
                            disabled={disabled}
                            size="large"
                          >
                            <IconBubbleChart />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={ts('common.action.delete')}>
                          <IconButton
                            ref={buttonRef}
                            component="button"
                            color={
                              displayAction === 'removeUsers'
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => {
                              setDisplayAction('removeUsers');
                              setAnchorEl(buttonRef.current);
                            }}
                            disabled={disabled}
                            data-testid={
                              TestIds.pages.users.actionOnSelectedUsers
                                .removeUsersButton
                            }
                            size="large"
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title={ts('common.action.export')}>
                      <IconButton
                        color={
                          displayAction === 'exportUsers'
                            ? 'primary'
                            : 'default'
                        }
                        onClick={() => setDisplayAction('exportUsers')}
                        disabled={disabled}
                        size="large"
                      >
                        <IconGetApp />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    {!selectAllEnabled &&
                      selectedIds.length > MAX_SELECTED &&
                      !isMobileP &&
                      !loading && (
                        <CustomStatus
                          type="error"
                          label={ts('table.maxSelection', {
                            value: MAX_SELECTED
                          })}
                        />
                      )}
                    {totalCount <= MAX_SELECTED &&
                      !selectAllEnabled &&
                      !loading &&
                      searchTerm.length === 0 && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => onSelect(null)}
                          data-testid={
                            TestIds.table.selectAllElement.selectAllButton
                          }
                          style={{ marginLeft: '8px' }}
                        >
                          {ts('table.selectAll')}
                        </Button>
                      )}

                    {selectAllEnabled && !loading && (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => onUnselect()}
                        data-testid={
                          TestIds.table.selectAllElement.unselectAllButton
                        }
                        style={{ marginLeft: '8px' }}
                      >
                        {ts('table.unselectAll')}
                      </Button>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              {displayAction === 'addSubscriptions' && (
                <AddSubscriptions
                  selectedUserIds={selectedIds}
                  selectAllUserEnabled={selectAllEnabled}
                  selectedUserCount={selectedCount}
                  onCollapse={onCollapse}
                />
              )}
              {displayAction === 'removeUsers' && (
                <RemoveUsers
                  selectedUserIds={selectedIds}
                  selectAllUserEnabled={selectAllEnabled}
                  selectedUserCount={selectedCount}
                  onCollapse={onCollapse}
                  onReloadUsers={onReloadUsers}
                />
              )}
              {displayAction === 'removeUsers' && (
                <Popper
                  open={true}
                  placement="top"
                  anchorEl={anchorEl}
                  modifiers={[
                    {
                      name: 'arrow',
                      enabled: true,
                      options: {
                        element: arrowRef
                      }
                    }
                  ]}
                  className={classes.popper}
                >
                  <span
                    className={classes.arrow}
                    ref={element => setArrowRef(element)}
                  />
                  <RemoveUsers
                    selectedUserIds={selectedIds}
                    selectAllUserEnabled={selectAllEnabled}
                    selectedUserCount={selectedCount}
                    onCollapse={onCollapse}
                    onReloadUsers={onReloadUsers}
                  />
                </Popper>
              )}
              {displayAction === 'exportUsers' && (
                <ExportUsers
                  selectedUserIds={selectedIds}
                  searchTerm={searchTerm}
                  selectAllUserEnabled={selectAllEnabled}
                  onCollapse={onCollapse}
                />
              )}
            </Accordion>
          </Drawer>
        </>
      )}
    </CurrentPerson.Consumer>
  );

  function onCollapse() {
    setDisplayAction(null);
  }
  function onUnselect() {
    setDisplayAction(null);
    onSelect([]);
  }
}

export default withStyles(styles)(ActionOnSelectedUsers);
