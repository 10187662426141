import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: '#e0e6fc'
    },
    section: {
        marginTop: theme.spacing(3)
    },
    titleWithHelp: {
        display: 'flex', 
        alignItems: 'center'
    },
    notificationPaper: {
        margin: '1em 0px',
        padding: '1em',
    },
    notificationTitle: {
        display: 'flex', 
        flexDirection: 'column', 
        paddingLeft: '1em',
        maxWidth: '100%'
    },
    leftSide: {
        display: 'flex', 
    },
    switchZone:{
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}));