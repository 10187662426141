import { ActivationStatus } from 'Apollo/fragments/person/Person';
import CustomStatus from '../CustomStatus';
import useTranslate from 'Hooks/useTranslate';
interface Props {
  person: IPerson;
  size?: string;
}

function UserStatusChip({ person, size }: Readonly<Props>) {
  const ts = useTranslate();
  switch (person.status) {
    case ActivationStatus.Activated:
      return (
        <CustomStatus
          type="success"
          label={ts('page.userProfile.section.personal.status.activated')}
          size={size}
        />
      );
    case ActivationStatus.ToActivate:
      return (
        <CustomStatus
          type="pending"
          label={ts('page.userProfile.section.personal.status.toActivate')}
          size={size}
        />
      );
    case ActivationStatus.Created:
      return (
        <CustomStatus
          type="warning"
          label={ts('page.userProfile.section.personal.status.created')}
          size={size}
        />
      );
    case ActivationStatus.Disabled:
      return (
        <CustomStatus
          type="error"
          label={ts('page.userProfile.section.personal.status.disabled')}
          size={size}
        />
      );

    default:
      return null;
  }
}

export default UserStatusChip;
