import { ServiceSubscriptionOrder } from 'Apollo';
import useTranslate from './useTranslate';
import Skeleton from 'Theme/components/Skeleton';
import { MoreHorizOutlined as MoreIcon } from '@mui/icons-material';
import { TableCollectionColumn } from '../Components/Shared';
import ServiceProfileMembersLink from '../Components/ServiceProfileMembersLink';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import CellService from 'Components/Shared/CellService';
import CellAdministratedValidation from 'Components/Shared/CellAdministratedValidation';
import CellAccessByDefault from 'Components/Shared/CellAccessByDefault';

export interface IServiceColumnsLightConfig {
  isServiceHidden?: boolean;
  isDescriptionHidden?: boolean;
  isFamilyHidden?: boolean;
  isAccountHidden?: boolean;
  isSubscribersHidden?: boolean;
  isAdminValidationHidden?: boolean;
  isAccessByDefaultHidden?: boolean;
}

export const defaultServicesConfigLight: IServiceColumnsLightConfig = {
  isServiceHidden: false,
  isDescriptionHidden: true,
  isFamilyHidden: true,
  isAccountHidden: true,
  isSubscribersHidden: false,
  isAdminValidationHidden: true,
  isAccessByDefaultHidden: true
};

export function CustomRenderCell(serviceSubscription: Readonly<IServiceSubscription>) {
  return <div style={{maxWidth: '300px', wordWrap: 'break-word'}}>
    {serviceSubscription.service.groupCode === 'ADM-2' ? 
      serviceSubscription.targetAccount!.name 
      : serviceSubscription.account.name
    }
  </div>
};

export const useColumnsServicesLight = (
  columnsConfigLight: IServiceColumnsLightConfig
): TableCollectionColumn<IServiceSubscriptionWithCount>[] => {
  const ts = useTranslate();

  const accountOrder = {
    asc: ServiceSubscriptionOrder.AccountLabelAscThenNameAsc,
    desc: ServiceSubscriptionOrder.AccountLabelDescThenNameAsc
  }

  return [
    {
      key: 'service',
      orderBy: {
        asc: ServiceSubscriptionOrder.NameAsc,
        desc: ServiceSubscriptionOrder.NameDesc
      },
      hidden:
        columnsConfigLight.isServiceHidden ?? defaultServicesConfigLight.isServiceHidden,
      renderHead: () => ts('page.userProfile.services.service'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => <CellService serviceSubscription={serviceSubscription} />
    },
    {
      key: 'description',
      hidden:
        columnsConfigLight.isDescriptionHidden ??
        defaultServicesConfigLight.isDescriptionHidden,
      renderHead: () => ts('common.terminology.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (
        <IconButtonWithPopOver
          customIcon={<MoreIcon />}
          description={serviceSubscription.service.description ?? ''}
        />
      )
    },
    {
      key: 'family',
      orderBy: {
        asc: ServiceSubscriptionOrder.FamilyAscThenNameAsc,
        desc: ServiceSubscriptionOrder.FamilyDescThenNameAsc
      },
      hidden:
        columnsConfigLight.isFamilyHidden ?? defaultServicesConfigLight.isFamilyHidden,
      renderHead: () => ts('page.userProfile.services.family'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => serviceSubscription.service.family
    },
    {
      key: 'account',
      orderBy: accountOrder,
      hidden:
        columnsConfigLight.isAccountHidden ?? defaultServicesConfigLight.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) =>
        CustomRenderCell(serviceSubscription)
    },
    {
      key: 'subscribers',
      hidden:
        columnsConfigLight.isSubscribersHidden ??
        defaultServicesConfigLight.isSubscribersHidden,
      renderHead: () => ts('common.service.access'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscriptionWithCount;
      }) => (
        <ServiceProfileMembersLink serviceSubscription={serviceSubscription} />
      )
    },
    {
      key: 'administratedValidation',
      hidden:
        columnsConfigLight.isAdminValidationHidden ??
        defaultServicesConfigLight.isAdminValidationHidden,
      renderHead: () => ts('page.userProfile.services.withValidation'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (
        <CellAdministratedValidation
          serviceSubscription={serviceSubscription}
        />
      )
    },
    {
      key: 'accessByDefault',
      hidden:
        columnsConfigLight.isAccessByDefaultHidden ??
        defaultServicesConfigLight.isAccessByDefaultHidden,
      renderHead: () => ts('page.userProfile.services.auto'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => <CellAccessByDefault serviceSubscription={serviceSubscription} />
    }
  ];
};
