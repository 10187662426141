import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useTranslate from '../../Hooks/useTranslate';
import { generatePath, useParams, Link } from 'react-router-dom';
import TestIds from 'Tests/TestIds';
import { PrivateRoutes } from '../../Router/Routes';
import { useStyles } from './AccessRightsCard.styles';
import PersonAvatar from 'Theme/components/Avatar/PersonAvatar';
import { PersonAccessRightStatus } from '../../Apollo';
import { DatePickerButton } from '../Shared';
import { userFullName } from 'Tools';
import { LocalContext } from 'Context/Local.context';
import { PersonContext } from 'Context/CurrentPerson';
import Alert from 'Components/Shared/Alert/Alert';
import { FormattedDate } from 'react-intl';

type Props = {
  data: IPersonAccessRight;
  onCardUpdate(a: Partial<IUpdateAccessRightRequestVars>): void;
};

const AccessRightsCard = ({ data, onCardUpdate }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(undefined);
  const { availableAccounts } = useContext(LocalContext);
  const { currentPersonRoles } = useContext(PersonContext);
  const { accountCode } = useParams<IUriParams>();

  const serviceIdentification = {
    accountCode: data.account?.code || null,
    profileImmutableId: data.person.immutableId,
    serviceCode: data.service.groupCode
  };

  const initDatePicker = () => {
    setExpiryDate(undefined);
  };

  const handleDeny = () => {
    onCardUpdate({
      ...serviceIdentification,
      processStatus: PersonAccessRightStatus.Refused
    });
  };

  const handleAccept = () => {
    onCardUpdate({
      ...serviceIdentification,
      processStatus: PersonAccessRightStatus.Accepted,
      expiryDate: expiryDate?.toUTCString()
    });
  };

  return (
    <Box>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography
            variant="h6"
            component={'span'}
            display="block"
            noWrap
            data-testid={TestIds.component.accessRightRequests.serviceName + data.service.name}
          >
            {data.service.name}
          </Typography>
          {availableAccounts.length > 1 && (
            <Typography
              component={'span'}
              display="block"
              variant="caption"
              noWrap
              data-testid={TestIds.component.accessRightRequests.accountCode}
              style={{ maxWidth: '300px' }}
            >
              {data.account?.code} - {data.account?.name}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {!!expiryDate && (
            <Alert
              severity="info"
              icon={null}
              onClose={initDatePicker}
              sx={{ margin: '0 !important', padding: '0px 8px', justifyContent: 'flex-end' }}
              content={
                <>
                  {ts(
                    'page.serviceProfile.serviceMembers.expirationDateAlert.accessUntil'
                  )}
                  <FormattedDate
                    value={expiryDate}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                  {' - '}
                  <FormattedDate
                    value={expiryDate}
                    hour="2-digit"
                    minute="2-digit"
                  />
                </>
              }
            />
          )}
          {!expiryDate && (
            <Grid item pr={1}>
              <DatePickerButton
                onAccept={(expiryDate: Date) => setExpiryDate(expiryDate)}
                data-testid={TestIds.component.accessRightCard.datePickerButton}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container pt={1}>
        <Grid item sm={4}>
          <Grid container>
            <Grid item p={1} pl={0}>
              <PersonAvatar
                component="span"
                size="medium"
                person={data.person}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {userFullName(data.person?.firstName, data.person?.lastName)}
              </Typography>
              {currentPersonRoles.hasAccessToAccounts ? (
                <Link
                  to={
                    data.person?.immutableId !== undefined &&
                    generatePath(PrivateRoutes.usersProfile.path, {
                      accountCode: data.account?.code || accountCode,
                      immutableId: data.person?.immutableId
                    })
                  }
                  color="primary"
                  className={classes.seeProfileLink}
                >
                  <Typography variant="caption">
                    {ts('common.action.seeProfile')}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="body2">{data.person?.email}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8}>
          <Grid container pt={1} justifyContent={'flex-end'}>
          
            
            <Grid item pr={1}>
              <Tooltip title={ts('common.action.deny')}>
                <Button
                  variant="text"
                  size="small"
                  color="inherit"
                  startIcon={<CloseIcon />}
                  onClick={handleDeny}
                  data-testid={TestIds.component.accessRightRequests.denyButton}
                >
                  {ts('common.action.deny')}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={handleAccept}
                data-testid={
                  TestIds.component.accessRightRequests.activateButton
                }
              >
                {ts('common.action.accept')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessRightsCard;
