import { TableCell, TableRow, Typography, Theme, Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { PersonAvatar } from 'Theme/components';
import RemoveMemberButton from './RemoveMemberButton';
import { userFullName } from 'Tools';
import UserStatusChip from 'Components/Shared/Chips/UserStatusChip/UserStatusChip';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      width: theme.spacing(5),
      padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
        1
      )} ${theme.spacing(2)}`
    },
    status: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    identity: {
      padding: theme.spacing(2)
    }
  });

interface Props extends WithStyles<typeof styles> {
  person: IPersonWithAccount;
  personGroup: IPersonGroup;
  onRefetch: any;
}

function PersonGroupPersonsRow(props: Readonly<Props>) {
  const accountName = props.person?.account?.name ?? '';
  const accountCode = props.person?.account?.code ?? '';
  const zipcode = props.person?.account?.zipCode ?? '';
  const city = props.person?.account?.city ?? '';

  const rowContent = `${accountName +
    ' - ' +
    zipcode +
    ' ' +
    city +
    ' / ' +
    accountCode}`;


  return (
    <TableRow>
      <TableCell classes={{ root: props.classes.label }}>
        <PersonAvatar person={props.person} size="medium" role='avatar'/>
      </TableCell>
      <TableCell classes={{ root: props.classes.identity }}>
        <Typography variant="subtitle1">
          {userFullName(props.person.firstName, props.person.lastName)}
        </Typography>
        <Typography variant="body2" paragraph>
          {props.person.email ?? ''}
        </Typography>
        <Hidden smUp>
          <Typography variant="subtitle1" style={{maxWidth: '100%', wordBreak: 'break-word'}} paragraph>{rowContent}</Typography>
          <UserStatusChip person={props.person} />
        </Hidden>
      </TableCell>
      <Hidden only="xs">
        <TableCell>
          <Typography variant="subtitle1" style={{maxWidth: '100%', wordBreak: 'break-word'}}>{rowContent}</Typography>
        </TableCell>
        <TableCell classes={{ root: props.classes.status }}>
          <UserStatusChip person={props.person} />
        </TableCell>
      </Hidden>
      <TableCell classes={{ root: props.classes.status }}>
        <RemoveMemberButton
          person={props.person}
          personGroup={props.personGroup}
          onRefetch={props.onRefetch}
        />
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(PersonGroupPersonsRow);
