import { PersonAccessRightStatus } from '../../../../../../Apollo';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import CustomStatus from 'Components/Shared/Chips/CustomStatus';

interface Props {
  accessRight: IPersonAccessRight;
}

function AccessRightAvailability({ accessRight }: Readonly<Props>) {
  const ts = useTranslate();

  let today = new Date();
  let comparedDate = new Date(accessRight.expirationDate!);

  const isexpired = accessRight.expirationDate !== null && comparedDate < today;

  if (accessRight.isAvailable) {
    switch (accessRight.status) {
      case PersonAccessRightStatus.ToValidate:
        return (
          <div data-testid={TestIds.component.accessRightAvailability.status.pending}>
            <CustomStatus
              type="warning"
              label={ts('page.userProfile.services.toValidate')}
            />
          </div>
        );
      case PersonAccessRightStatus.Refused:
        return (
          <div data-testid={TestIds.component.accessRightAvailability.status.denied}>
            <CustomStatus
              type="error"
              label={ts('page.userProfile.services.refused')}
            />
          </div>
        );
      case PersonAccessRightStatus.Suspended:
        return (
          <div data-testid={TestIds.component.accessRightAvailability.status.suspended}>
            <CustomStatus
              type="error"
              label={ts('page.userProfile.services.suspendedLabel')}
            />
          </div>
        );
      default:
        return isexpired ? (
          <div data-testid={TestIds.component.accessRightAvailability.status.expired}>
            <CustomStatus
              type="warning"
              label={ts('page.userProfile.services.expired')}
            />
          </div>
        ) : (
          <div data-testid={TestIds.component.accessRightAvailability.status.available}>
            <CustomStatus
              type="success"
              label={ts('page.userProfile.services.available')}
            />
          </div>
        );
    }
  }

  return (
    <div data-testid={TestIds.component.accessRightAvailability.status.disabled}>
      <CustomStatus
        type="error"
        label={ts('page.userProfile.services.unavailable')}
      />
    </div>
  );
}

export default AccessRightAvailability;
