import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import * as React from 'react';
import getColumnsWidth from './getColumnsWidth';
import TestIds from 'Tests/TestIds';
import useTranslate from '../../../Hooks/useTranslate';
import { ITableMessages } from '../../../Components/Shared';
import { useStyles } from '../../../Components/Shared/Table/Table.styles';
import clsx from 'clsx';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import { TableCollectionPagination } from './TableCollectionPagination';
import Alert from 'Components/Shared/Alert/Alert';
import CustomStatus from 'Components/Shared/Chips/CustomStatus';
import { useEffect } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface TableCollectionColumn {
  key: string;
  orderBy?: {
    asc: string;
    desc: string;
  };
  width?: number; // number between 0 and 1, being the width in %
  hidden?: boolean;
  renderHead: () => React.ReactNode;
  renderSkeleton: () => React.ReactNode;
  renderCell: (props: { row: any }) => React.ReactNode;
}

interface Props {
  loading: boolean;
  columns: TableCollectionColumn[];
  rows: any[];
  disabledRows?: any[];
  getRowId: (row: any) => string;
  orderBy?: string;
  onChangeOrderBy?: (orderBy: string) => void;
  rowsPerPage: number;
  rowsPerPageOptions?: any;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  page: number;
  onChangePage: (page: number) => void;
  selectedIds: string[] | null;
  onUnselect: (ids: string[] | null) => void;
  onSelect: (ids: string[] | null) => void;
  maxSelected?: number;
  totalCount: number;
  messages?: ITableMessages;
  isSelectionDisabled?: boolean;
  isSelectionHidden?: boolean;
  isSelectAllDisabled?: boolean;
  emptyStateData?: React.ReactNode;
}

function TableCollection(props: Readonly<Props>) {
  const defaultProps = {
    isSelectionDisabled: false,
    isSelectionHidden: false,
    isSelectAllDisabled: false
  };

  const {
    onChangePage,
    onChangeRowsPerPage,
    getRowId,
    selectedIds,
    rows,
    disabledRows,
    rowsPerPageOptions,
    rowsPerPage,
    isSelectionHidden,
    isSelectionDisabled,
    isSelectAllDisabled,
    maxSelected,
    messages,
    totalCount,
    page,
    loading
  } = props;

  const classes = useStyles();
  const ts = useTranslate();

  const [selectAllOptionsOpen, setSelectAllOptionsOpen] = React.useState<
    boolean
  >(false);

  const totalSelected = selectedIds == null ? totalCount : selectedIds.length;
  const isMaxSelectedExceeded = maxSelected ? totalSelected > maxSelected : false;
  const isPageAllSelected = (selectedIds != null && rows.length > 0 && 
    rows.every(row => selectedIds.includes(getRowId(row)))) || selectedIds === null;

  const renderTableBody = (
    visibleColumns: TableCollectionColumn[],
    visibleColumnsWidth: string[]
  ) => {
    return rows.map((row, index) => (
      <TableRow
        key={index as any}
        id={getRowId(row)}
        selected={selectedIds?.includes(getRowId(row))}
        className={clsx([
          selectedIds?.indexOf(getRowId(row)) !== -1 ? classes.selectedRow : '',
          disabledRows !== undefined &&
          disabledRows?.indexOf(getRowId(row)) !== -1
            ? classes.disabledRow
            : '',
          isSelectionHidden ? classes.noHoverRow : ''
        ])}
      >
        {!isSelectionHidden && (
          <TableCell
            padding="checkbox"
            id={'tableRowUID-' + getRowId(row) + '-checkboxCell'}
          >
            <Checkbox
              id={'checkboxUID-' + getRowId(row)}
              color="primary"
              onChange={event =>
                event.target.checked
                  ? props.onSelect([getRowId(row)])
                  : props.onUnselect([getRowId(row)])
              }
              inputProps={{
                'aria-disabled':
                  disabledRows !== undefined &&
                  disabledRows?.indexOf(getRowId(row)) !== -1,
                disabled:
                  disabledRows !== undefined &&
                  disabledRows?.indexOf(getRowId(row)) !== -1
              }}
              checked={selectedIds?.indexOf(getRowId(row)) !== -1}
              value={getRowId(row)}
              disabled={
                isSelectionDisabled ||
                defaultProps.isSelectionDisabled ||
                (disabledRows !== undefined &&
                  disabledRows?.indexOf(getRowId(row)) !== -1) ||
                selectedIds === null
              }
            />
          </TableCell>
        )}
        {visibleColumns.map((column, index) => (
          <TableCell
            key={column.key}
            classes={{
              root: classes.cellRoot
            }}
            style={{
              width: visibleColumnsWidth[index] ?? 'auto'
            }}
          >
            {column.renderCell({ row })}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderMaxSelectionRow = (visibleColumns: TableCollectionColumn[]) => {
    if (isMaxSelectedExceeded)
      return (
        <TableRow id="isMaxSelectedExceededRow" className={classes.noHoverRow}
        >
          <TableCell colSpan={visibleColumns.length + 1} align="center">
            {messages?.maxSelectedExceeded && (
              <div className={classes.notice}>
                <Alert
                  severity="error"
                  icon={<ErrorOutlineIcon />}
                  content={ts(messages.maxSelectedExceeded, {
                    count: maxSelected
                  })}
                />
              </div>
            )}
          </TableCell>
        </TableRow>
      );

    if (
        isPageAllSelected &&
      isSelectAllDisabled === false && maxSelected !== undefined && messages !== undefined
    )
      return (
        <TableRow className={classes.allSelectedRow}>
          <TableCell colSpan={visibleColumns.length + 1} align="center">
            <div className={classes.notice}>
              <Typography
                variant="body2"
                data-testid={
                  TestIds.table.selectAllElement.selectedElementCount
                }
                paragraph
              >
                {ts(messages.pageAllSelected, {
                  count: totalSelected
                })}
              </Typography>
              {(totalCount <= maxSelected || maxSelected === undefined) &&
                selectedIds !== null && (
                  <Button
                    variant={'contained'}
                    color="primary"
                    onClick={() => props.onSelect(null)}
                    data-testid={TestIds.table.selectAllElement.selectAllButton}
                  >
                    {ts(messages.selectAll, {
                      count: totalCount
                    })}
                  </Button>
                )}
              {selectedIds === null && (
                <Button
                  variant={'contained'}
                  color="secondary"
                  onClick={() => props.onUnselect(null)}
                   data-testid={TestIds.table.selectAllElement.unselectAllButton}
                >
                  {ts('table.unselectAll')}
                </Button>
              )}
              {!!maxSelected && totalCount > maxSelected && (
                <>
                  <Box height="8px" />
                  <CustomStatus
                    variant="dark"
                    label={ts('table.maxSelection', {value: maxSelected})}
                  />
                </>
              )}
            </div>
          </TableCell>
        </TableRow>
      );

    return null;
  };

  const visibleColumns = props.columns.filter(column => !column.hidden);
  const visibleColumnsWidth = getColumnsWidth(
    visibleColumns.map(column => column.width)
  ).map(width => `${width * 100}%`);
  const isEmpty = totalCount === 0;

  useEffect(() => {
    if (selectedIds !== null && selectedIds.length === 0) {
      setSelectAllOptionsOpen(false);
    }
    if (selectedIds !== null && selectedIds.length !== 0) {
      setSelectAllOptionsOpen(true);
    }
    if (selectedIds === null) {
      setSelectAllOptionsOpen(true);
    }
  }, [selectedIds]);

  if (isEmpty && !loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {props.emptyStateData}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.tableRoot}>
          <TableHead className={classes.tableHead}>
            <TableRow id="firstTableHeadRow">
              {!isSelectionHidden && (
                <TableCell padding="checkbox">
                  <Checkbox
                    id={'firstTableHeadRow-checkbox'}
                    color="primary"
                    onChange={() => {
                      if (selectedIds == null || selectedIds.length > 0) {
                        props.onUnselect(null);
                      } else {
                        props.onSelect(rows.map(row => getRowId(row)));
                      }
                    }}
                    checked={
                      !loading &&
                      (selectedIds === null ||
                        selectedIds.length === totalCount)
                    }
                    indeterminate={
                      selectedIds !== null &&
                      selectedIds.length < totalCount &&
                      selectedIds.length > 0
                    }
                    disabled={
                      isSelectAllDisabled ?? defaultProps.isSelectAllDisabled
                    }
                  />
                </TableCell>
              )}
              {visibleColumns.map((column, index) => (
                <TableCell
                  key={column.key}
                  style={{
                    width: visibleColumnsWidth[index] ?? 'auto'
                  }}
                >
                  {props.onChangeOrderBy == null || column.orderBy == null ? (
                    column.renderHead()
                  ) : (
                    <TableSortLabel
                      onClick={() => {
                        if (column.orderBy == null) {
                          return;
                        }
                        props.onChangeOrderBy!(
                          props.orderBy === column.orderBy.desc
                            ? column.orderBy.asc
                            : column.orderBy.desc
                        );
                      }}
                      active={
                        props.orderBy === column.orderBy.asc ||
                        props.orderBy === column.orderBy.desc
                      }
                      direction={
                        props.orderBy === column.orderBy.asc ? 'asc' : 'desc'
                      }
                    >
                      {column.renderHead()}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectAllOptionsOpen && !isSelectionDisabled && renderMaxSelectionRow(visibleColumns)}
            {loading
              ? Array.from({ length: rowsPerPage }).map((_, rowIndex) => (
                  <TableRow key={rowIndex as any}>
                    {!isSelectionDisabled && !isSelectionHidden && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          id={'loading-checkbox-' + rowIndex}
                          checked={false}
                        />
                      </TableCell>
                    )}
                    {visibleColumns.map((column, columnIndex) => (
                      <TableCell
                        key={column.key}
                        style={{
                          width: visibleColumnsWidth[columnIndex] ?? 'auto'
                        }}
                      >
                        {column.renderSkeleton()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : renderTableBody(visibleColumns, visibleColumnsWidth)}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEmpty && (
        <TableCollectionPagination
          variant="outlined"
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions ?? ROW_PER_PAGE_OPTIONS}
          onChangeRowsPerPage={(newRowPerPage: number) =>
            onChangeRowsPerPage(newRowPerPage)
          }
          page={page}
          onChangePage={(newPage: number) => onChangePage(newPage)}
          totalCount={totalCount}
        />
      )}
    </>
  );
}

export default TableCollection;
