import useTranslate from 'Hooks/useTranslate';
import { generatePath } from 'react-router';
import { PrivateRoutes } from 'Router/Routes';
import useTabsAccountProfile from 'Hooks/useTabsAccountProfile';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';

interface Props {
  account: IAccountWithNotificationSettings;
}

function AccountProfile(props: Readonly<Props>) {
  const { account } = props;

  const tabs = useTabsAccountProfile({ account });
  const ts = useTranslate();

  const basePath = generatePath(PrivateRoutes.accountProfile.path, {
    accountCode: account?.code
  });

  return (
    <ProfilePageTemplate
      basePath={basePath}
      tabs={tabs}
      title={ts('page.accountProfile.title', {
        value: account.code
      })}
    />
  );
}

export default AccountProfile;
