import { useState, useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { BubbleChart as IconBubbleChart } from '@mui/icons-material';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { ServiceSubscriptionOrder } from 'Apollo/fragments/service/ServiceSubscription';
import LocalStorage from 'Tools/localStorage';
import ActionOnSelectedSubscriptions from '../ActionOnSelectedSubscriptions/ActionOnSelectedSubscriptions';
import { TableCollectionColumnSelection } from 'Theme/components/';
import { MAX_SELECTED } from 'Theme/config';
import ExpandedSearch from '../../../Components/Shared/ExpandedSearch';
import { stringifyServiceSubscriptionNode } from '../../../Tools/stringifyServiceSubscriptionNode';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import { useColumnsServices } from 'Hooks/useColumnsServices';
import { useStyles } from './ServicesTable.styles';
import ContextualHelp from 'Components/Shared/ContextualHelp';
import { useColumnsServicesLight } from 'Hooks/useColumnsServicesLight';
import EmptyState from 'Components/Shared/EmptyState/EmptyState';
import ErrorMsg from 'Components/Shared/ErrorMsg/ErrorMsg';
import { checkErrorMessage } from 'Tools/checkErrorCode';

type Props = {
  rows: IServiceSubscription[];
  totalCount: number;
  loading: boolean;
  isMobileP: boolean;
  currentPage: number;
  orderBy: ServiceSubscriptionOrder;
  rowsPerPage: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onChangeServicesSearch: (value: string) => void;
  onChangePage: (page: number) => void;
  onChangeOrderBy: (order: ServiceSubscriptionOrder) => void;
  error?: any;
};

const ServicesTable = ({
  rows,
  totalCount,
  currentPage,
  orderBy,
  rowsPerPage,
  loading,
  isMobileP,
  onChangePage,
  onChangeOrderBy,
  onChangeRowsPerPage,
  onChangeServicesSearch,
  error
}: Props) => {
  const classes = useStyles();
  const ts = useTranslate();

  let isTimeoutError = false;

  if(error?.graphQLErrors) {
    isTimeoutError = checkErrorMessage(error, 'HC0045');
  }
 
  const initialColumns = useColumnsServices({});
  const initialLightColumns = useColumnsServicesLight({});

  const [columns, setColumns] = useState(
    LocalStorage.deserializeColumns(
      useColumnsServices({}),
      LocalStorage.ServicesColumns ?? []
    )
  );

  const [lightColumns, setLightColumns] = useState(
    LocalStorage.deserializeColumns(
      useColumnsServicesLight({}),
      LocalStorage.LightServicesColumns ?? []
    )
  );
  const [selectedIds, setSelectedIds] = useState<string[] | null>([]);

  const getKeyObject = (serviceSubscription: IServiceSubscription) => {
    return {
      serviceCode: serviceSubscription?.service?.groupCode,
      accountCode: serviceSubscription?.account?.code,
      targetAccountCode: serviceSubscription?.targetAccount
        ? serviceSubscription.targetAccount.code
        : null
    };
  };

  const rowIndex = useMemo(() => {
    const index: Record<string, IServiceSubscription> = {};
    rows.forEach(r => {
      index[JSON.stringify(getKeyObject(r))] = r;
    });
    return index;
  }, [rows]);

  const onSelect = (ids: string[] | null) => {
    if (ids == null) {
      setSelectedIds(null);
      return;
    }
    setSelectedIds(
      ids.reduce(
        (acc, id) => (acc.includes(id) ? acc : acc.concat([id])),
        selectedIds || []
      )
    );
  };

  const onUnselect = (ids: string[] | null) => {
    if (ids == null) {
      setSelectedIds([]);
      return;
    }

    setSelectedIds(
      selectedIds == null ? [] : selectedIds.filter(id => !ids.includes(id))
    );
  };

  const selectedCount = selectedIds == null ? totalCount : selectedIds.length;

  const handleSearch = (search: string) => {
    onChangeServicesSearch(search);
    setSelectedIds([]);
  }

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <Typography variant="h5">
              {ts('page.services.tableTitle')}
            </Typography>
            <ContextualHelp content={ts('page.services.help')} />
          </div>
          <div className={classes.toolbarRight}>
            <TableCollectionColumnSelection
              columns={isMobileP ? lightColumns : columns}
              onChange={newColumns => {
                if (isMobileP) {
                  setLightColumns(newColumns);
                  LocalStorage.LightServicesColumns = LocalStorage.serializeColumns(
                    newColumns
                  );
                } else {
                  setColumns(newColumns);
                  LocalStorage.ServicesColumns = LocalStorage.serializeColumns(
                    newColumns
                  );
                }
              }}
              onReset={() => {
                if (isMobileP) {
                  setLightColumns(initialLightColumns);
                  LocalStorage.LightServicesColumns = null;
                } else {
                  setColumns(initialColumns);
                  LocalStorage.ServicesColumns = null;
                }
              }}
            />
          </div>
        </div>
        <ExpandedSearch
          onChange={handleSearch}
          testId={TestIds.pages.services.searchServiceInput + 'ServicesTable'}
          placeholder={ts('component.searchBars.placeholder.services')}
        />
        {!loading && error ? (
          <ErrorMsg customError={isTimeoutError ? ts("error.type.HC0045") : undefined} />
        ) : (
          <TableCollection
            loading={loading}
            columns={isMobileP ? lightColumns : columns}
            rows={rows}
            disabledRows={rows
              .filter(row => row.isAvailable === false)
              .map(row => stringifyServiceSubscriptionNode(row))}
            getRowId={(serviceSubscription: IServiceSubscriptionWithCount) =>
              stringifyServiceSubscriptionNode(serviceSubscription)
            }
            orderBy={orderBy}
            onChangeOrderBy={(order: string) =>
              onChangeOrderBy(order as ServiceSubscriptionOrder)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={currentPage}
            onChangePage={onChangePage}
            onUnselect={onUnselect}
            selectedIds={selectedIds}
            onSelect={onSelect}
            maxSelected={MAX_SELECTED}
            totalCount={totalCount}
            messages={{
              maxSelectedExceeded:
                'page.userProfile.services.maxSelectedExceeded',
              pageAllSelected: 'page.userProfile.services.pageAllSelected',
              selectAll: 'page.userProfile.services.selectAll'
            }}
            emptyStateData={
              <EmptyState
                icon={<IconBubbleChart />}
                primary={ts('page.services.table.emptyStatePrimary')}
              />
            }
          />
        )}
      </Paper>
      {selectedIds !== null && (
        <ActionOnSelectedSubscriptions
          selectedIds={selectedIds}
          selectedCount={selectedIds == null ? selectedCount : selectedIds.length}
          rowIndex={rowIndex}
          disabled={selectedCount > MAX_SELECTED}
        />
      )}
    </>
  );
};

export default ServicesTable;
