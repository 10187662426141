import {
  CdsColorBrandNeutral40,
  CdsColorBackgroundWarnLight,
  CdsColorTextWarnHighEmphasis,
  CdsColorBackgroundCriticalLight,
  CdsColorTextCriticalHighEmphasis,
  CdsColorBackgroundSuccessLight,
  CdsColorTextSuccessHighEmphasis,
  CdsColorTextInfoHighEmphasis,
  CdsColorBackgroundInfoLight,
  CdsColorBrandNeutral90
} from '@cegid/design-tokens';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import { useStyles } from './CustomStatus.styles';

interface Props {
  label: string;
  type?: 'success' | 'error' | 'warning' | 'pending' | 'info';
  size?: string;
  variant?: 'light' | 'dark';
}

function CustomStatus({ size, label, type, variant }: Readonly<Props>) {
  const classes = useStyles();

  const defaultSize = 'small';

  switch (type) {
    case 'info':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: CdsColorBackgroundInfoLight
          }}
        >
          <InfoOutlinedIcon
            fontSize="small"
            style={{ color: CdsColorTextInfoHighEmphasis }}
          />
          <Box width="4px" />
          <Typography
            variant="caption"
            color={CdsColorTextInfoHighEmphasis}
            role="custom-status-info"
          >
            {label}
          </Typography>
        </div>
      );
    case 'success':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: CdsColorBackgroundSuccessLight
          }}
        >
          <CheckCircleOutlineIcon
            fontSize="small"
            style={{ color: CdsColorTextSuccessHighEmphasis }}
          />
          <Box width="4px" />
          <Typography
            variant="caption"
            color={CdsColorTextSuccessHighEmphasis}
            role="custom-status-success"
          >
            {label}
          </Typography>
        </div>
      );
    case 'warning':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: CdsColorBackgroundWarnLight
          }}
        >
          <ErrorOutlineIcon
            fontSize="small"
            style={{ color: CdsColorTextWarnHighEmphasis }}
          />
          <Box width="4px" />
          <Typography
            variant="caption"
            color={CdsColorTextWarnHighEmphasis}
            role="custom-status-warning"
          >
            {label}
          </Typography>
        </div>
      );
    case 'pending':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: CdsColorBackgroundCriticalLight
          }}
        >
          <AccessTimeIcon
            fontSize={defaultSize}
            style={{ color: CdsColorTextCriticalHighEmphasis }}
          />
          <Box width="4px" />
          <Typography
            variant="caption"
            color={CdsColorTextCriticalHighEmphasis}
            role="custom-status-pending"
            fontSize={size ?? defaultSize}
          >
            {label}
          </Typography>
        </div>
      );
    case 'error':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor:
              variant === 'dark'
                ? CdsColorTextCriticalHighEmphasis
                : CdsColorBackgroundCriticalLight
          }}
        >
          <ErrorOutlineIcon
            fontSize="small"
            style={{
              color:
                variant === 'dark' ? 'white' : CdsColorTextCriticalHighEmphasis
            }}
          />
          <Box width="4px" />
          <Typography
            variant="caption"
            color={
              variant === 'dark' ? 'white' : CdsColorTextCriticalHighEmphasis
            }
            role="custom-status-error"
          >
            {label}
          </Typography>
        </div>
      );
    default:
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor:
              variant === 'dark' ? CdsColorBrandNeutral40 : CdsColorBrandNeutral90
          }}
        >
          <Typography
            variant="caption"
            color={variant === 'dark' ? 'white' : CdsColorBrandNeutral40}
            role="custom-status-default"
          >
            {label}
          </Typography>
        </div>
      );
  }
}

export default CustomStatus;
