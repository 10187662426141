import { Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import DataRow from 'Theme/components/DataRow/DataRow';

interface Props {
  account: IAccount;
}

function SectionAbout(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();

  return (
    <>
      <DataRow
        label={ts('page.accountProfile.sections.about.accountName')}
        divider={true}
      >
        <Typography sx={{ wordWrap: 'break-word', maxWidth: '100%' }}>{account?.name}</Typography>
      </DataRow>
      <DataRow
        label={ts('page.accountProfile.sections.about.accountCode')}
        divider={true}
      >
        <Typography>{account?.code}</Typography>
      </DataRow>
      <DataRow label={ts('common.terminology.status')}>
        <Typography>
          {account?.active
            ? ts('common.terminology.active')
            : ts('common.terminology.inactive')}
        </Typography>
      </DataRow>
    </>
  );
}

export default SectionAbout;
