import * as React from 'react';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { AccountsListSkeleton } from './AccountsList.skeleton';
import { useStyles } from './AccountsList.styles';
import { useQuery } from '@apollo/client';
import { GetAccounts } from 'Apollo';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { Link, generatePath } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';

function AccountsList() {
  const classes = useStyles();

  let { data, loading } = useQuery<GetAccountsData>(GetAccounts, {
    variables: {
      page: { first: 2, offset: 0 }
    },
    fetchPolicy: 'cache-first'
  });

  return (
    <>
      {loading && <AccountsListSkeleton />}
      {!loading && data && (
        <List>
          {data?.accounts.edges.map((edge, index) => {
            const isLastItem = index === data!.accounts.edges.length - 1;

            const tiersName = shortAccountlabel(edge.node);

            return (
              <React.Fragment key={edge.node.code}>
                <ListItem key={edge.node.code}>
                  <Link
                    to={generatePath(PrivateRoutes.accountProfile.path, {
                      accountCode: edge.node.code
                    })}
                    style={{ width: '100%' }}
                  >
                    <ListItemText 
                      primary={tiersName} 
                      primaryTypographyProps={{style: { textOverflow: 'ellipsis', overflow: 'hidden'}}} 
                    />
                  </Link>
                </ListItem>
                {!isLastItem && <Divider className={classes.divider} />}
              </React.Fragment>
            );
          })}
        </List>
      )}
    </>
  );
}

export default AccountsList;
