import { useParams } from 'react-router-dom';
import AccountsList from './AccountsList';
import CurrentAccount from 'Page/Dashboard/Accounts/CurrentAccount';
import { Paper } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PaperTitle from 'Theme/components/PaperTitle';

function Accounts() {
  const { accountCode } = useParams<IUriParams>();

  return(
    <Paper sx={{ width: '100%' }}>
      <PaperTitle
        variant="subtitle1"
        title={<FormattedMessage id="page.dashBoard.index.accountList" />}
      />
      {accountCode === "all" && (<AccountsList />) }
      {accountCode !== "all" && (<CurrentAccount accountCode={accountCode}/>)}
    </Paper>
  )

}

export default Accounts;
