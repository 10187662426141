import CustomStatus from '../CustomStatus';
import useTranslate from 'Hooks/useTranslate';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';
interface Props {
  domain: IOrganizationDomain;
}

function DomainStatusChip({ domain }: Readonly<Props>) {
  const ts = useTranslate();
  switch (domain.validationMode) {
    case DomainValidationMode.Strong:
      return (
        <CustomStatus
          type="success"
          label={ts('page.organizationDomains.statusType', {
            value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
          })}
        />
      );
    case DomainValidationMode.None:
      return (
        <CustomStatus
          type="pending"
          label={ts('page.organizationDomains.statusType', {
            value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
          })}
        />
      );
    case DomainValidationMode.Weak:
      return (
        <CustomStatus
          type="warning"
          label={ts('page.organizationDomains.statusType', {
            value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
          })}
        />
      );

    default:
      return null;
  }
}

export default DomainStatusChip;
