import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { Add as AddIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GetLightProfileWithAccessRight, PersonOrder } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import { TServiceMemberList } from 'Models';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import EmailFormContainer from 'Components/EmailForm/EmailForm.container';
import TableServicesMembers from './TableServicesMembers';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { useStyles } from './ServiceMembersPanel.styles';
import TestIds from 'Tests/TestIds';
import ExpandedSearch from './../../Components/Shared/ExpandedSearch';
import { calculateNewPage } from 'Tools/calculateNewPage';
import useTableHandlers from 'Hooks/useTableHandlers';
import InviteMembersDrawer from './Drawers/InviteMembersDrawer';
import ServiceMembersActionsDrawer from './Drawers/ServiceMembersActionsDrawer';

const loadingTotalCount = 1000;

export type ServiceMembersMode =
  | 'add'
  | 'invite'
  | 'confirmRemove'
  | 'setExpiration'
  | null;

interface Props {
  serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
  allowExternalInvitation: boolean;
}

const ServiceMembersPanelContainer = ({
  serviceSubscriptionIdentifier,
  allowExternalInvitation
}: Readonly<Props>) => {
  const ts = useTranslate();
  const classes = useStyles();
  const { onError } = useNotification();
  const [mode, setMode] = useState<ServiceMembersMode>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const onUpdate = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  const { data, loading: loadingQuery, error: queryError, refetch } = useQuery<{
    lightProfileWithAccessRight: TServiceMemberList;
  }>(GetLightProfileWithAccessRight, {
    onError,
    onCompleted() {},
    variables: {
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      searchTerm: searchTerm,
      orderBy: PersonOrder.NameAsc,
      serviceSubscriptionIdentifier
    }
  });

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
    refetch();
  };

  const handleChange = (search: string) => {
    setSearchTerm(search);
    setCurrentPage(0);
    refetch();
  };

  const rows = data
    ? data.lightProfileWithAccessRight.edges.map(o => o.node)
    : [];

  const selectOneUserToRemove = async (row: ILightPersonProfile) => {
    setMode('confirmRemove');
    setSelectedIds([row.immutableId]);
  };

  const selectOneUserToUpdate = async (row: ILightPersonProfile) => {
    setMode('setExpiration');
    setSelectedIds([row.immutableId]);
  };

  return (
    <>
      <div className={classes.header}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" paragraph>
              {ts('page.serviceProfile.serviceMembers.tableTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setMode('add');
                setSelectedIds([]);
              }}
              data-testid={TestIds.pages.serviceProfile.giveAccessButton}
            >
              {ts('page.serviceProfile.serviceMembers.giveAccess')}
            </Button>
          </Grid>
        </Grid>
      </div>
      {queryError ? (
        <ErrorMsg />
      ) : (
        <>
          <ExpandedSearch
            onChange={handleChange}
            testId={TestIds.pages.serviceProfile.searchMemberInput}
            placeholder={ts('component.searchBars.placeholder.users')}
          />
          <TableServicesMembers
            selectedIds={selectedIds}
            onselect={handleSelect}
            totalCount={
              data?.lightProfileWithAccessRight.totalCount ?? loadingTotalCount
            }
            rowsPerPage={rowsPerPage}
            page={currentPage}
            rows={rows}
            loading={loadingQuery}
            setPage={setCurrentPage}
            onRemoveUser={r => selectOneUserToRemove(r)}
            onUpdateUserSubscription={r => selectOneUserToUpdate(r)}
            onUnselect={handleUnselect}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </>
      )}
      <Dialog open={mode === 'invite'} maxWidth="xs" fullWidth>
        <DialogTitle>
          {ts('page.services.action.subscribeExternalUser')}
        </DialogTitle>
        <DialogContent>
          <EmailFormContainer
            serviceSubscriptions={[serviceSubscriptionIdentifier]}
            onCancel={() => setMode(null)}
            onSubmit={() => {
              setMode(null);
              refetch();
            }}
          />
        </DialogContent>
      </Dialog>
      {mode === 'add' && (
        <InviteMembersDrawer
          serviceSubscriptionIdentifier={serviceSubscriptionIdentifier}
          setMode={(mode: ServiceMembersMode) => setMode(mode)}
          allowExternalInvitation={allowExternalInvitation}
          refetch={refetch}
        />
      )}
      {mode !== 'add' && selectedIds.length > 0 && (
        <ServiceMembersActionsDrawer
          serviceSubscriptionIdentifier={serviceSubscriptionIdentifier}
          setMode={(mode: ServiceMembersMode) => setMode(mode)}
          refetch={refetch}
          currentMode={mode}
          selectedIds={selectedIds}
          totalCount={data?.lightProfileWithAccessRight.totalCount ?? 0}
          updateSelection={(ids: string[]) => setSelectedIds(ids)}
        />
      )}
    </>
  );
};

export default ServiceMembersPanelContainer;
